import React from "react";
import { Route, Routes } from "react-router-dom";

/* Providers */
import { useApp } from "./AppProvider";
import { InventoryProvider } from "./pages/Inventory/InventoryProvider";
import { SetProvider } from "./pages/Set/SetProvider";
/* Pages */
import { Inventory } from "./pages/Inventory";
import { Set } from "./pages/Set";
/* Components */
import { Header } from "./components/molecules/Header";
import { ItemForm } from "./components/organism/ItemForm";
import { SetForm } from "./components/organism/SetForm";

import { INVENTORY, SET } from "./util/Constants";
import "./App.scss";

function App() {
  const { isAdmin, root } = useApp();

  return (
    <div ref={root} className="App">
      <Header />
      <Routes>
        <Route
          exact
          path={INVENTORY}
          element={
            <InventoryProvider>
              <Inventory />
            </InventoryProvider>
          }
        />
        <Route
          exact
          path={SET}
          element={
            <SetProvider>
              <Set />
            </SetProvider>
          }
        />
        <Route
          path={`${SET}/:id`}
          element={
            <SetProvider>
              <Set />
            </SetProvider>
          }
        />
      </Routes>
      {isAdmin && (
        <>
          <ItemForm />
          <SetForm />
        </>
      )}
    </div>
  );
}

export default App;
